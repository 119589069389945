<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <!----------------------------------------POLICY NUMBERS----------------------------------------->
    <base-material-card
      color="primary"
      icon="mdi-chart-pie"
      title="Sales By Policy"
      class="px-4 py-3"
    >
      <v-row class="d-flex justify-center mt-0">
        <v-col
          cols="9"
        >
          <v-row class="d-flex justify-center">
            <v-col
              cols="6"
            >
              <v-select
                v-model="quarter"
                outlined
                :items="quarters"
                label="Select Quarter"
                @change="showSelectedData(year, quarter)"
              ></v-select>
            </v-col>
            <v-col
              cols="6"
            >
              <v-select
                v-model="year"
                outlined
                :items="years"
                label="Select Year"
                @change="showSelectedData(year, quarter)"
              ></v-select>
            </v-col>
          </v-row>
          <v-card class="pa-4 mt-2">
            <v-card-title class="d-flex justify-center">
              {{ quarter }} Sales By Policy
            </v-card-title>
            <div id="chart">
              <v-row class="d-inline-flex justify-center ml-4">
                <v-col>
                  <div>{{ quarter }} {{ year }}</div>
                </v-col>
              </v-row>
              <apexchart
                ref="salesByType"
                type="bar"
                height="350"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      color="primary"
      icon="mdi-file-table"
      title="Sales By Policy"
      class="px-4 py-3"
    >
      <v-row class="d-flex justify-center">
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="policyData"
            item-key="name"
            class="elevation-1"
          ></v-data-table>
        </v-col>
      </v-row>
    </base-material-card>
    <!-----------------------------------POLICY VISUALS------------------------------->
  </v-container>
</template>

<script>

  export default {

    data: () => ({
      headers: [{
                  text: 'Year',
                  value: 'year',
                  align: 'start',
                  sortable: false,
                },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Travel', value: 'travel' },
                { text: 'Pet', value: 'pet' },
                { text: 'Home', value: 'home' },
                { text: 'Renters', value: 'renters' },
                { text: 'Auto', value: 'auto' },
                { text: 'Term Life', value: 'termLife' },
                { text: 'Total', value: 'total' }
      ],
      policyData: [{ year: '2022', quarter: 'Q1', travel: '44', pet: '53', home: '12', renters: '9', auto: '25', termLife: '30', total: '173' }],
      years: ['2021', '2022'],
      year: '',
      quarters: ['Q1', 'Q2', 'Q3', 'Q4'],
      quarter: 'Q1',
      series: [{ data: [] }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            }
          }
        },
        // colors: colors,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$' + val + ' K'
            }
          }
        },
        xaxis: {
          categories: ['Travel', 'Pet', 'Renters', 'Home', 'Auto', 'Term Life'],
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val + 'K'
            }
          }

        },
      }
    }),
    created () {
      this.getYear()
    },
    methods: {
      getYear () {
        const d = new Date()
        let year = d.getFullYear().toString()
        this.year = year
        this.currentYear = year
      },
      showSelectedData (year, quarter) {
        let salesByType = this.$store.getters.salesByType
        let quarterlySalesByType = salesByType.filter((item) => {
          return item.year === year && item.quarter === quarter
        })
        let newSeries = []
        quarterlySalesByType.forEach((item) => { newSeries.push(item.data[0]) })
        this.$refs.salesByType.updateSeries([{ data: newSeries }])
      }
    }
  }
</script>

<style lang="scss" scoped>
.policy-numbers {
  font-size: 36px;
}
</style>
